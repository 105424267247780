import CryptoJS from 'crypto-js'
import Base64 from 'crypto-js/enc-base64';
import { Dialog } from 'vant'

// 获取路径参数
export function getParams(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// 验证手机号
export function isMobile(mobile) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(mobile);
}

// 验证邮箱
export function isEmail(email) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(email);
}

// 四舍五入保留2位小数(若第二位小数为0，则保留一位小数)
export function toFixNumber(num, d = 100) {
  return Math.round(num * d) / d;
}

/**
 * 处理答案
 * questionSystemType === 1或2 取answerContent 多个题连接
 * questionSystemType === 3或4 取answerContent 用“；”分割
 * questionSystemType === 5或6 取answerContent 换行
 * @param {Object} question 题目
 * @param {Array} question.answerFiles 题目答案
 * @param {string|number} question.questionSystemType 题目类型
 */
export function handleAnswer(question) {
  const { answerFiles, questionSystemType } = question;
  if (answerFiles && answerFiles.length) {
    if (questionSystemType == 1 || questionSystemType == 2) {
      return answerFiles
        .map((item) => replaceImg(item.answerContent, 4))
        .join();
    }
    if (questionSystemType == 3 || questionSystemType == 4) {
      return answerFiles
        .map((item) => replaceImg(item.answerContent, 4))
        .join("；");
    }
    if (questionSystemType == 5 || questionSystemType == 6) {
      return answerFiles
        .map((item) => replaceImg(`${item.answerContent}<br />`, 4))
        .join("");
    }
  } else {
    return "";
  }
}

export function replaceImg(str, type = "") {
  return str?.replace(
    /(<img.*?&type=)\d(.*?class="formula-latex")/g,
    `$1${type}$2`
  );
}

/**
 * 通过key和iv解密内容
 * @param content 加密内容
 * @return {*}
 */
export function decrypt(content) {
  let key = process.env.VUE_APP_KEY
  key = Base64.parse(key).toString(CryptoJS.enc.Utf8)
  key = key.split('').reverse().join('')
  let iv = process.env.VUE_APP_IV
  iv = Base64.parse(iv).toString(CryptoJS.enc.Utf8)
  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Utf8.parse(iv)
  return CryptoJS.AES.decrypt(content, key, {
    iv
  }).toString(CryptoJS.enc.Utf8)
}

/**
 * 获取试卷中所有题目
 * @param list 试卷groupList
 * @param {boolean} wrong 是否为错题
 * @return {*}
 */
export function getAllQuestions(list, wrong = false) {
  const allQuestions = list.reduce((prev, curr) => {
    if (wrong) {
      prev.push(...curr.questionList.filter(item => item.wrong))
    } else {
      prev.push(...curr.questionList)
    }
    return prev
  }, [])
  return allQuestions
}

// 判断是pc端还是移动端
export function isOnMobile() {
  const device = navigator.userAgent.toLocaleLowerCase()
  return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)
}

// 报告未生成提示
export function noReport(show) {
  if (show) {
    Dialog.alert({
      message: '正在努力生成报告，预计需要2～3分钟，请稍后查看',
      confirmButtonText: '我知道了'
    }).then(() => {
      history.back()
    })
  }
}


//1,先将base64转换为blob
export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
//2,再将blob转换为file
export function blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();  // 文件最后的修改日期
    theBlob.name = fileName;                // 文件名
    return new File([theBlob], fileName, {type: theBlob.type, lastModified: Date.now()});
}


export function saveLocalImg(img){
    const local = getLocalImgList()
    local.push(img)
    localStorage.setItem('localImgUrl', JSON.stringify(local))
}
export function replaceImgList(list){
    localStorage.setItem('localImgUrl', JSON.stringify(list))
}
export function getLocalImgList(){
    let local =  localStorage.getItem('localImgUrl')
    try {
        local = JSON.parse(local) || []
    }catch (e){
        local = []
    }
    return local
}


export function NoToChinese(num) {
    if (!/^\d*(\.\d*)?$/.test(num.toString())) {
        return '';
    }
    let AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
    let BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
    let a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (let i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    if (a.length > 1) //加上小数部分(如果有小数部分)
    {
        re += BB[6];
        for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    }
    return re;
}
export function sleep(time){
    return new Promise(resolve => setTimeout(resolve, time))
}

// 手机location.replace失效
export function locationReplace(url){
	if(history.replaceState){
		history.replaceState(null, document.title, url);
		history.go(0);
	}else{
		location.replace(url);
	}
}
