import axios from "axios";
import { Toast } from "vant";
import store from "../store";

axios.defaults.timeout = 25000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export function GET(url, params = []) {
  return fetch(url, "get", params[0], params[1]);
}

export function POST(url, params = []) {
  return fetch(url, "post", params[0], params[1]);
}

export function DELETE(url, params = []) {
  return fetch(url, "delete", params[0], params[1]);
}

export function PATCH(url, params = []) {
  return fetch(url, "patch", params[0], params[1]);
}

export function PUT(url, params = []) {
  return fetch(url, "put", params[0], params[1]);
}

/**
 * 请求失败回调函数
 * @callback errorCallback
 * @param {string} errorText
 * @param {*} res
 */

/**
 * @function fetch 请求
 * @param {string} url 接口地址
 * @param {string} method 请求方法
 * @param {*} params 请求参数
 * @param {Object} [option={}] 请求配置
 * @param {boolean} [option.load] 接口请求过程中 是否显示 load
 * @param {boolean} [option.loadNoClose] load true的情况下 请求完成也不关闭load
 * @param {string|boolean} [option.successMsg] 请求成功 提示信息  默认false不提示  string:'信息' true：显示后端信息
 * @param {string|boolean} [option.errorMsg] 错误信息 默认true 显示后端错误信息, string:信息 false 不提示
 * @param {boolean} [option.bolb] 后端传输二进制数据
 * @param {errorCallback} [option.error] 请求失败回调函数
 * @param {Function} [option.finally] 请求完成，不管是成功还是失败都会调用
 * @param {string} [option.baseURL] 请求基本路径
 * @param {Object} [option.headers] 请求头
 */
function fetch(url, method, params, option = {}) {
  if (
    (option.load || !Object.prototype.hasOwnProperty.call(option, "load")) &&
    !option.loadNoClose
  ) {
    Toast.loading({
      duration: 0,
      forbidClick: true,
    });
  }
  const request = {
    method,
    url,
    headers: Object.assign(option.headers || {}, {}),
  };
  if (/get/i.test(method)) {
    request.params = params;
  } else {
    request.data = params;
  }
  if (option.blob) {
    request.responseType = "blob";
  }
  if (option.baseURL) {
    request.baseURL = option.baseURL;
  }
  return axios(request)
    .then(({ data }) => {
      if (!option.loadNoClose) {
        Toast.clear();
      }
      if (option.successMsg) {
        Toast.success({
          message: typeof option.successMsg === 'string' ? option.successMsg : data.msg,
          className: 'ticeWidth'
        });
      }
      option.finally && option.finally();
      return data;
    })
    .catch((e) => {
      const response = e.response || {};
      Toast.clear();
      const errorMsg =
        option.errorMsg ||
        (response && response.msg) ||
        response.data?.msg ||
        codeError(response.status) ||
        response.statusText ||
        "未知错误";
      if (option.errorMsg !== false && response.data?.code !== 510) {
        Toast.fail({ message: errorMsg, duration: 5000, className: 'res-error-toast' })
      }
      option.error && option.error(errorMsg, e.response);
      option.finally && option.finally();
      switch (response.data.code) {
        case 401:
          localStorage.removeItem("openId");
          // 等待Toast显示完后再跳转微信授权
          setTimeout(() => {
            store.dispatch("linkAuth");
          }, 1500);
          break
      }
      return Promise.reject(errorMsg);
    });
}

/**
 * 失败回调处理
 * @param {number} code 状态码
 * @returns {string|undefined}
 */
function codeError(code) {
  switch (code) {
    case 404:
      return "404找不到";
    case 401:
      return "登录已过期";
    case 500:
    case 502:
    case 504:
      return "服务器错误";
  }
}
