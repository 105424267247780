import { GET, POST } from './axios';
import { noReport } from '@/utils';

/* Common */
/**
 * 学校列表
 * @param {string} [params.fabricated] 是否是虚拟学校，0: 不是 1: 是 2或其他: 所有学校
 * @param {string} [params.name] 学校名称
 * @param {string} [params.provinceCode] 省
 * @param {string} [params.cityCode] 市
 * @param {string} [params.countyCode] 区
 * @param {string} [params.schoolType] -1:展示全部,0:普通学校 4:培训机构
 */
export function getSchools(params, option) {
  return GET('/common/school', [params, option]);
}

/* 
  展示错题本
 */
export function valiShowWrongQues (params, option) {
  return POST(`/api/report/displayRule`, [params, option]);
}

/**
 * 班级
 * @param {number} schoolId 学校id
 */
export function getClasses(schoolId) {
  return GET(`/common/school/${schoolId}/class`);
}

/**
 * 科目
 */
export function getSubjects() {
  return GET('/common/subject');
}

/**
 * 获取微信sdk验证
 * @param {Array<string>} params.jsApiList 微信api接口列表
 * @param {string} params.url 当前网页地址
 */
export function getJsapiTicket(params) {
  return POST('/v1/wechat/buildConfig', [params]);
}

/* UserInfo 用户信息 */

/**
 * 获取微信授权地址
 * @return {Promise<*>}
 */
export function getAuthUrl() {
  return GET('/v1/wechat/authUrl');
}

/**
 * 微信授权回调
 * @param {string} params.code 微信授权回调code值
 * @param {string} params.state 固定值STATE
 */
export function auth(params) {
  return GET('/v1/wechat/callback', [params]);
}

/**
 * 获取登录验证码
 * @param {string} params.phone 手机号
 */
export function getLoginCode(params) {
  return GET('/login/code', [params]);
}

/**
 * 登录
 * @param {string} params.phone 手机号
 * @param {string} params.code 验证码
 * @param {string} params.openid 公众号openid
 * @param {string} params.unionid 公众号unionid
 * @param {string} params.nickname 昵称
 * @param {string} params.headImgUrl 头像
 */
export function login(params, option) {
  return POST('/login', [params, option]);
}

/**
 * 登出
 */
export function logOut(params, option) {
  return POST('/login/out', [params, option]);
}

/**
 * 验证学生
 * @param {string} params.schoolId 学校id
 * @param {Array<number>} params.studentNoSet 学号
 * @param {string} params.userName 用户名
 */
export function validateStudent(params, option) {
  return POST('/user/validateExamStudent', [params, option]);
}
export function validateStudentByN(params, option) {
  return POST('/user/validateStudent', [params, option]);
}

/**
 * 绑定学生
 * @param {string} params.studentId 学生id
 */
export function bindStudent(params, option) {
  return POST('/user/bindStudent', [params, option]);
}

/**
 * 获取绑定学生列表
 */
export function getBindStudents(params, option) {
  return GET('/user/bindStudent', [params, option]);
}

/**
 * 学习派埋点
 */
export function xxpTrace() {
  return GET('/user/xxpTrace');
}

/* Exam 联考 */
/**
 * 首页
 */
export function getHome(params, option) {
  return GET('/report', [params, option]);
}

/**
 * 联考的学生列表
 * @param {string} params.examinationId 联考id
 */
export function getReportStu({ examinationId, answerId }, option) {
  if (answerId) {
    return GET(`/report/${examinationId}/offline/answer`, [{}, option]);
  } else {
    return GET(`/report/${examinationId}/student`, [{}, option]);
  }
}

/**
 * 联考的学生报告总览
 * @param {string} params.examinationId 联考id
 * @param {string} params.studentId 学生id
 */
export function getHaveInfo({ examinationId, studentId, answerId, ...params }, option) {
  if (answerId) {
    return GET(`/report/${examinationId}/offline/answer/${answerId}/base`, [
      params,
      {
        ...option,
        error: (msg, res) => {
          noReport(res.data.code === 510);
        }
      }
    ]);
  } else {
    return GET(`/report/${examinationId}/student/${studentId}/base`, [params, option]);
  }
}

/**
 * 联考的学生报告详情
 * @param {string} params.examinationId 联考id
 * @param {string} params.studentId 学生id
 * @param {string} params.classId 班级id
 * @param {string} params.subjectId 科目id
 * @param {string} params.schoolId 学校id
 */
export function getHaveDetails({ examinationId, studentId, answerId, ...params }, option) {
  if (answerId) {
    return GET(`/report/${examinationId}/offline/answer/${answerId}/paper`, [params, option]);
  } else {
    return GET(`/report/${examinationId}/student/${studentId}/paper`, [params, option]);
  }
}

/**
 * 获取题目相似题
 * @param {string} params.questionId 题目Id
 * @param {string} params.studentId 学生id
 * @param {string} params.wrongFlag 是否是错题，1错题，0正确
 */
export function getSimilar(params, option) {
  return POST('/similar', [params, option]);
}

/**
 * 创建pdf
 * @param {string} params.examinationId 联考Id
 * @param {string} params.examinationReportId 联考报告id
 * @param {string} params.studentId 学生id
 * @param {string} params.subjects 选择的科目
 */
export function downloadPdf(params, option) {
  return POST('/report/downloadPdfNew', [params, option]);
}

/**
 * 联考购物车添加
 * @param {string} params.examinationId 联考Id
 * @param {string} params.studentId 学生id
 * @param {string} params.studentName 科目
 * @param {string} params.subjectId 科目id
 * @param {string} params.questionId 题目id
 */
export function addCart(params, option) {
  if (params.studentId) {
    return POST('/questionCart/cart', [params, option]);
  } else {
    return POST('/questionCart/v3/cart', [params, option]);
  }
}

/**
 * 联考购物车移除
 * @param {string} params.examinationId 联考Id
 * @param {string} params.studentId 学生id
 * @param {string} params.studentName 科目
 * @param {string} params.subjectId 科目id
 * @param {string} params.questionId 题目id
 */
export function removeCart(params, option) {
  if (params.studentId) {
    return POST('/questionCart/removeCart', [params, option]);
  } else {
    return POST('/questionCart/v3/removeCart', [params, option]);
  }
}

/**
 * 联考购物车详情
 */
export function getCartInfo({ answerId, ...params }, option) {
  if (answerId) {
    return GET('/questionCart/v3/myCart', [params, option]);
  } else {
    return GET('/questionCart/myCart', [params, option]);
  }
}

/**
 * 联考的上传学生分数记录列表
 * @param {string} examinationId 联考Id
 */
export function getReportAnswer(examinationId, option) {
  return GET(`/report/${examinationId}/offline/answer`, [{}, option]);
}

/* Order 订单 */
/**
 * 生成token
 * @param {string} params.force 是否强制刷新token
 * @param {string} params.productId 商品id
 */
export function getOrderToken(params, option) {
  return POST('/reportOrder/token', [params, option]);
}

/**
 * 主商品下单
 * @param {string} params.examinationId 联考id
 * @param {Array} params.studentList 学生列表
 */
export function createMainOrder({ examAnswerId, ...params }, option) {
  if (examAnswerId) {
    return POST('/reportOrder/v3/main', [{ examinationId: params.examinationId, examAnswerId }, option]);
  } else {
    return POST('/reportOrder/main', [params, option]);
  }
}

/**
 * 购物车下单
 * @param {string} params.examinationId 联考id
 */
export function createCartOrder({ answerId, ...params }, option) {
  if (answerId) {
    return POST('/reportOrder/v3/cart', [params, option]);
  } else {
    return POST('/reportOrder/cart', [params, option]);
  }
}

/**
 * 整卷下单
 * @param {string} params.examinationId 联考id
 * @param {Array} params.studentList 学生列表
 */
export function createOrder(params, option) {
  if (params.studentList) {
    return POST('/reportOrder/create', [params, option]);
  } else {
    return POST('/reportOrder/v3/create', [params, option]);
  }
}

/**
 * 支付
 * @param {string} orderNo 订单号
 * @param {number} type 商品类型 1=联考toB 2=试卷 3=联考toC 4=余额充值
 */
export function payOrder(orderNo, type) {
  if (type === 1) {
    return POST(`/pay/${orderNo}`);
  } else if (type === 2) {
    return POST(`/paperPay/${orderNo}`);
  } else if (type === 3) {
    return POST(`/pay/reportPay/${orderNo}`);
  } else if (type === 4) {
    return POST(`/pay/rechargePay/${orderNo}`);
  } else {
    return POST(`/pay/finePaperPay/${orderNo}`);
  }
}

/**
 * 取消订单
 * @param {string} orderNo 订单号
 */
export function cancelOrder(orderNo) {
  return POST('/order/cancel', [{ orderNo }]);
}

/**
 * 余额支付未完成订单
 * @param {string} orderNo 订单号
 */
export function payUsePoints(orderNo) {
  return POST(`/pay/usePoints${orderNo}`);
}

/**
 * 订单列表
 * @param {number} params.page 页码
 * @param {number} params.size 页栈
 * @param {number} params.status 订单状态
 */
export function getOrderList(params) {
  return GET('/order', [params]);
}

/* pdf 报告 */
/**
 * 获取pdf报告任务
 * @param {string} params.taskId 任务id
 */
export function pdfTask(params, option) {
  return GET('/v2/studyReport/taskInfo', [params, option]);
}

/**
 * 获取pdf报告子任务
 * @param {string} params.taskId 任务id
 */
export function pdfChildTask(params, option) {
  return GET('/v2/studyReport/childTaskInfo', [params, option]);
}

/**
 * 获取学生信息
 * @param {string} params.examinationId 联考Id
 * @param {string} params.studentId 学生id
 */
export function getBaseInfo({ examinationId, studentId, answerId }, option) {
  if (answerId) {
    return GET('/v2/offlineStudyReport/baseInfo', [{ examinationId, answerId }, option]);
  } else {
    return GET('/v2/studyReport/baseInfo', [{ examinationId, studentId }, option]);
  }
}

/**
 * pdf报告总览
 * @param {string} params.examinationId 联考id
 * @param {string} params.studentId 学生id
 */
export function getPdfInfo({ answerId, ...params }, option) {
  if (answerId) {
    return GET('/v2/offlineStudyReport/haveReportInfo', [{ examinationId: params.examinationId, answerId }, option]);
  } else {
    return GET('/v2/studyReport/haveReportInfo', [params, option]);
  }
}

/**
 * pdf报告详情
 * @param {string} params.examinationId 联考id
 * @param {string} params.studentId 学生id
 * @param {string} params.classId 班级id
 * @param {string} params.subjectId 科目id
 * @param {string} params.schoolId 学校id
 */
export function getPdfDetails({ answerId, ...params }, option) {
  if (answerId) {
    return GET('/v2/offlineStudyReport/haveDetails', [{ examinationId: params.examinationId, answerId, subjectId: params.subjectId }, option]);
  } else {
    return GET('/v2/studyReport/haveDetails', [params, option]);
  }
}

/**
 * pdf精准训练
 * @param {string} params.questionId 题目Id
 * @param {string} params.studentId 学生id
 * @param {string} params.wrongFlag 是否是错题，1错题，0正确
 */
export function getPdfSimilar(params, option) {
  return POST('/v2/studyReport/getSimilarQuestion', [params, option]);
}

/**
 * pdf联考信息
 * @param {string} params.examinationId 联考id
 */
export function getPdfExam({ answerId, ...params }, option) {
  if (answerId) {
    return GET('/v2/offlineStudyReport/reportStudent', [params, option]);
  } else {
    return GET('/v2/studyReport/reportStudent', [params, option]);
  }
}

/* 试卷 Paper */
/**
 * 学生试卷报告详情
 * @param {string} params.paperId 试卷id
 * @param {string} params.answerId 学生做题记录Id
 */
export function getPaperReport(params, option) {
  return GET('/paper/report', [params, option]);
}

/**
 * 生成试卷token
 * @param {string} params.force 是否强制刷新token
 * @param {Array<string>} params.paperIds 试卷ids
 */
export function getPaperToken(params, option) {
  return POST('/order/paper/token', [params, option]);
}

/**
 * 已购买的试卷数量
 * @param {string} params.force 是否强制刷新token
 * @param {Array<string>} params.paperIds 试卷ids
 */
export function getMyPaperNum(params, option) {
  return GET('/paper/myPaperNum', [params, option]);
}

/**
 * 试卷pdf导出
 * @param {string} params.paperId 试卷id
 * @param {string} params.answerId 答题记录id
 * @param {number} params.wrongFlag 是否是错题
 */
export function exportPaperPdf(params, option) {
  return GET('/paper/downloadPdf', [params, option]);
}

/**
 * 试卷pdf任务详情
 * @param {string} params.taskId 任务id
 */
export function paperPdfTask(params, option) {
  return GET('/v1/paper/taskInfo', [params, option]);
}

/**
 * 试卷pdf任务详情
 * @param {string} params.paperId 试卷id
 * @param {string} params.answerId 答题记录id
 * @param {number} params.wrongFlag 是否是错题
 * @param {number} params.userId 家长id
 */
export function paperPdfDetail(params, option) {
  return GET('/v1/paper/report', [params, option]);
}

/**
 * 我的余额
 */
export function getRemainSum(params, option) {
  return GET('/recharge/remainSum', [params, option]);
}

/**
 * 余额充值选项
 */
export function getRechargeEnum(params, option) {
  return GET('/recharge/enum', [params, option]);
}

/**
 * 余额充值流水
 * @param {string} params.page 页数 1
 * @param {string} params.size 每页条数 10
 */
export function getRechargeFlowing(params, option) {
  return GET('/recharge/flowing', [params, option]);
}

/**
 * 余额充值
 * @param {number} params.id
 */
export function recharge(params, option) {
  return POST('/recharge', [params, option]);
}

/**
 * 余额token
 * @param {number} params.id
 * @param {boolean} params.force
 */
export function rechargeToken(params, option) {
  return POST('/recharge/token', [params, option]);
}

/**
 * 充值支付
 * @param {number} orderNo
 */
export function rechargePay(orderNo) {
  return POST(`/pay/rechargePay/${orderNo}`);
}

/**
 * 首页精品联考
 * @param {string} params.page 页数 1
 * @param {string} params.size 每页条数 10
 */
export function getFineReport(params, option) {
  return GET('/fineReport', [params, option]);
}
