import { createRouter, createWebHashHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/home",
    component: IndexView,
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "家长端" },
        component: () => import("../views/home/HomeView.vue"),
      },
      {
        path: "/mine",
        name: "mine",
        meta: { title: "家长端" },
        component: () => import("../views/mine/MineView.vue"),
      },
      {
        path: "/paper",
        name: "paper",
        meta: { title: "家长端" },
        component: () => import("../views/paper/PaperView.vue"),
      },
      {
        path: "/homework",
        name: "homework",
        meta: { title: "启望家长" },
        component: () => import("../views/homework/WorkView.vue"),
      },
        {
            path: '/worktest',
            name: 'worktest',
            meta: { title: "家长端" },
            component: () => import("../views/workTest/index.vue"),
        },
      // {
      //   path: '/onlineHomework',
      //   name: 'onlineHomework',
      //   meta: { title: '在线作业' },
      //   component: () => import('../views/wrongQuestionBook/OnlineHomework.vue')
      // },
        {
          path: '/wrongQuestionBook',
          name: 'wrongQuestionBook',
          meta: { title: '错题本' },
          component: () => import('../views/wrongQuestionBook/WrongQuestionBook.vue')
        },
    ],
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/LoginView.vue"),
  },
  {
    path: "/bind",
    name: "bind",
    component: () => import("../views/bind/BindView.vue"),
  },
  {
    path: "/order",
    name: "order",
    meta: { title: "启望家长" },
    component: () => import("../views/mine/OrderView.vue"),
  },
  {
    path: "/balance",
    name: "balance",
    meta: { title: "我的余额" },
    component: () => import("../views/balance/myBalance.vue")
  },
  {
    path: "/agreement",
    name: "agreement",
    meta: { title: "充值协议"},
    component: () => import("../views/balance/agreeMent.vue")
  },
  {
    path: "/rechargeRecord",
    name: "rechargeRecord",
    meta: { title: "充值记录"},
    component: () => import("../views/balance/rechargeRecord.vue")
  },
  {
    path: "/exam/:id",
    name: "exam",
    meta: { title: "联考报告", keepAlive: false },
    component: () => import("../views/exam/ExamView.vue"),
  },
  {
    path: "/exam/:id/:answerId",
    name: "examToC",
    meta: { title: "联考详情" },
    component: () => import("../views/exam/ExamToC.vue"),
  },
  {
    path: '/exam/pdf',
    name: "examPdf",
    meta: { title: "联考详情" },
    component: () => import("../views/exam/ExamPdfIgnoreVW.vue"),
    children: [
      {
        path: "/exam/pdf/:taskId",
        name: "examPdfList",
        meta: { title: "启望家长" },
        component: () => import("../views/exam/ExamPdfList.vue"),
      },
      {
        path: "/exam/pdf/:taskId/subject",
        name: "examPdfSubject",
        meta: { title: "启望家长" },
        component: () => import("../views/exam/ExamPdfDetailIgnoreVW.vue"),
      },
    ]
  },
  {
    path: "/exam/shopping/:examinationId",
    name: "examShopping",
    meta: { title: "启望家长" },
    component: () => import("../views/exam/ShoppingCart.vue"),
  },
  {
    path: "/question",
    name: "question",
    meta: { title: "查看全部题目" },
    component: () => import("../views/exam/AllQuestion.vue"),
  },
  {
    path: "/intro",
    name: "intro",
    meta: { title: "启望家长" },
    component: () => import("../views/home/IntroView.vue"),
  },
  {
    path: "/myPaper",
    name: "myPaper",
    meta: { title: "我的试卷" },
    component: () => import("../views/paper/MyPaperView.vue"),
  },
  {
    path: "/paper/:id",
    name: "paperDetail",
    meta: { title: "启望家长" },
    component: () => import("../views/paper/PaperDetail.vue"),
  },
  {
    path: "/paper/exam/:id",
    name: "paperExam",
    meta: { title: "启望家长" },
    component: () => import("../views/paper/PaperSubjects.vue"),
  },
  {
    path: "/buyPage",
    name: "buyPage",
    meta: { title: "购买" },
    component: () => import("../views/paper/buyPage.vue"),
  },
  {
    path: "/uploadAc",
    name: "uploadAc",
    meta: { title: "上传考试成绩" },
    component: () => import("../views/paper/uploadAchievement.vue"),
  },
  {
    path: "/upload/:examId",
    name: "uploadSubject",
    meta: { title: "上传考试成绩" },
    component: () => import("../views/paper/UploadSubjectScore.vue"),
  },
  {
    path: "/paper/report",
    name: "paperReport",
    meta: { title: "启望家长" },
    component: () => import("../views/paper/PaperReport.vue"),
  },
  {
    path: "/question/paper",
    name: "paperQuestions",
    meta: { title: "查看全部题目" },
    component: () => import("../views/paper/AllQuestion.vue"),
  },
  {
    path: "/paper/pdf/:taskId",
    name: "paperPdf",
    meta: { title: "考试报告" },
    component: () => import("../views/paper/PaperPdfIgnoreVW.vue"),
  },
  {
    path: "/paper/pdf/:examId/:userId",
    name: "paperPdfList",
    meta: { title: "考试报告" },
    component: () => import("../views/paper/PaperPdfList.vue"),
  },
    {
        path:  '/Proofread',
        name: 'Proofread',
        meta: { title: "家长端" },
        component: () => import("../views/workTest/Proofread.vue"),
    },
    {
        path:  '/HomeworkHistory',
        name: 'HomeworkHistory',
        meta: { title: "作业记录" },
        component: () => import("../views/workTest/homeworkHistory.vue"),
    },
    {
        path:  '/person-report',
        name: 'personReport',
        meta: { title: "学情报告" },
        component: () => import("../views/workTest/report.vue"),
    },
    {
        path:  '/homework-report',
        name: 'homeworkReport',
        meta: { title: "作业报告" },
        component: () => import("../views/workTest/homeworkReport.vue"),
    },
    {
        path:  '/studentReport',
        name: 'studentReport',
        meta: { title: "个性化学情报告" },
        component: () => import("../views/workTest/studentReport.vue"),
    },
    {
      path: '/personReportAllQuestions',
      name: 'personReportAllQuestions',
      meta: { title: "查看全部题目" },
      component: () => import("../views/workTest/AllWrongQuestions.vue"),
    },
    {
        path:  '/clipPhoto',
        name: 'clipPhoto',
        meta: { title: "上传图片" },
        component: () => import("../views/workTest/photo/canvasClip.vue"),
    },
    {
        path:  '/photoList',
        name: 'photoList',
        meta: { title: "提交" },
        component: () => import("../views/workTest/photo/photoList.vue"),
    },
    {
        path:  '/ComingSoon',
        name: 'comingSoon',
        meta: { title: "敬请期待" },
        component: () => import("../views/workTest/ComingSoon.vue"),
    },
    {
        path:  '/boutiqueExam',
        name: 'boutiqueExam',
        meta: { title: "精品联考" },
        component: () => import("../views/home/components/boutiqueExam.vue"),
    },
  // {
  //   path: '/wrongQuestionBook',
  //   name: 'wrongQuestionBook',
  //   meta: { title: '错题本' },
  //   component: () => import('../views/wrongQuestionBook/WrongQuestionBook.vue')
  // },
  {
    path: '/wrongQuestionBook/PDF/:taskId',
    name: 'wrongQuestionBookPDF',
    meta: { title: '错题本导出' },
    component: () => import('../views/wrongQuestionBook/WrongQuestionPDFIgnoreVW.vue')
  }
];
if (process.env.VUE_APP_ENV !== "production") {
  routes.push({
    path: "/test",
    name: "test",
    meta: { title: "内部测试" },
    component: () => import("../views/TestView.vue"),
  });
}

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
