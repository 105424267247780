import { createApp } from "vue";
import VConsole from "vconsole";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Button,
  Form,
  Field,
  Popup,
  TreeSelect,
  RadioGroup,
  Radio,
  Search,
  List,
  Cell,
  CellGroup,
  Empty,
  Tabbar,
  TabbarItem,
  Image,
  Tab,
  Tabs,
  Divider,
  Checkbox,
  CheckboxGroup,
  Loading,
  PullRefresh,
  Overlay,
  Lazyload,
  Icon,
  Dialog,
  NavBar,
  Swipe,
  SwipeItem,
  Picker,
  Collapse,
  CollapseItem, Progress,
  DatetimePicker,
  ActionSheet
} from 'vant'
import "vant/lib/index.css";
import "flex.css";
import "./styles/theme.less";
import "./styles/common.less";

if (process.env.VUE_APP_ENV !== "production" && location.hostname !== 'localhost') {
  new VConsole();
}

const app = createApp(App);
app
  .use(store)
  .use(Collapse)
  .use(CollapseItem)
  .use(router)
  .use(Button)
  .use(Form)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(TreeSelect)
  .use(RadioGroup)
  .use(Radio)
  .use(Search)
  .use(List)
  .use(Cell)
  .use(CellGroup)
  .use(Empty)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Image)
  .use(Tab)
  .use(Tabs)
  .use(Divider)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Loading)
  .use(PullRefresh)
  .use(Overlay)
  .use(Icon)
  .use(Dialog)
  .use(NavBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(Progress)
  .use(DatetimePicker)
  .use(ActionSheet)
  .use(Lazyload, {
    lazyComponent: true
  });

store.commit("setAppId", process.env.VUE_APP_APPID);
store.dispatch("linkAuth").then((openId) => {
  openId && app.mount("#app");
});
