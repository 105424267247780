import { createStore } from "vuex";
import {getParams, locationReplace} from "@/utils";
import router from '@/router/index'
import { auth, getAuthUrl, login, getRemainSum ,logOut} from '@/httpService/services';
import exam from "./exam";
// import useWeixin from "@/composables/useWeixin";
import { useStore } from 'vuex';

const store = useStore();

export default createStore({
  state: {
    appId: "",
    userInfo: {},
    router:router
  },
  getters: {
    // authUrl: (state) => {
    //   return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    //     state.appId
    //   }&redirect_uri=${encodeURIComponent(
    //     location.href
    //   )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    // },
    getUserInfo() {
      return JSON.parse(localStorage.getItem("userInfo"))|| {};
    },
  },
  mutations: {
    setAppId(state, appId) {
      state.appId = appId;
    },
    setUserInfo(state, obj) {
      localStorage.setItem("userInfo", JSON.stringify(obj));
      state.userInfo = obj;
    },
  },
  actions: {
    /**
     * 跳转微信认证
     */
    async linkAuth({ commit }) {
      if (process.env.VUE_APP_ENV === "development") {
        await login({
          phone: "13906676604",
          code: "000000",
          openid: "o3zXO6uZMkkv7DBslZsvT7HLq6QI",
          unionid: "",
          nickname: "HYW",
          headImgUrl:
            "https://thirdwx.qlogo.cn/mmopen/vi_32/UVj2kOfzNop9ibNBb3kdKPKjicwrq1TEd94OzO5OqYVibXLWxSDAPx3rY78TWsqNusGfDasC21b4F1SFJickvGhTPg/132",
        });
        localStorage.setItem("openId", "o3zXO6uZMkkv7DBslZsvT7HLq6QI");
      }
      // useWeixin([], ["wx-open-launch-weapp"]);
      let openId = localStorage.getItem("openId");
      if (!openId) {
        const code = getParams("code");
        const stateParam = getParams("state");
        if (code) {
					console.log('链接',`${location.origin}${location.pathname}`)
          const res = await auth({ code, state: stateParam });
          openId = res.openid;
          localStorage.setItem("openId", openId);
          commit("setUserInfo", res);
          if (!res.bindMobile) {
						console.log(0)
            location.replace(`${location.origin}${location.pathname}#/login`);
						// locationReplace(`${location.origin}${location.pathname}#/login`);
          } else {
						console.log(1)
            location.replace(`${location.origin}${location.pathname}`);
						// locationReplace(`${location.origin}${location.pathname}`);
          }
        } else if (!/\/pdf/i.test(location.hash)) {
					console.log(2)
          const authRes = await getAuthUrl()
          location.replace(authRes.url)
					// locationReplace(authRes.url)
        } else {
          return true;
        }
      }
      return openId;
    },
    async logout({commit}){
      await logOut()
      router.replace('/login')
    }
  },
  modules: {
    exam,
  },
});
