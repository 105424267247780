<template>
  <div class="index">
    <div class="index__view">
      <router-view />
    </div>
    <van-tabbar route placeholder safe-area-inset-bottom :border="false">
      <van-tabbar-item replace to="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="require(`@/assets/icons/ic_home_${props.active ? 'sel' : 'nor'}.png`)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace :to="{path: '/worktest'}">
        <span>作业考试</span>
        <template #icon="props">
          <img :src="require(`@/assets/icons/ic_test_${props.active ? 'sel' : 'nor'}.png`)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace :to="{path: '/paper', query:{show: true}}">
        <span>精品试卷</span>
        <template #icon="props">
          <img :src="require(`@/assets/icons/ic_paper_${props.active ? 'sel' : 'nor'}.png`)" />
        </template>
      </van-tabbar-item>
      <!-- wx-open-launch-weapp 只能在线上显示 -->
<!--      <wx-open-launch-weapp class="van-tabbar-item" username="gh_28a08f285862" path="pages/index/index"-->
<!--        :env-version="envVersion" @launch="onLaunch">-->
<!--        <component :is="'script'" type="text/wxtag-template">-->
<!--          <component :is="'style'">-->
<!--            .mall {-->
<!--            text-align: center;-->
<!--            }-->
<!--            .mall-icon {-->
<!--            height: 21.333333vw;-->
<!--            margin-bottom: 4.666667vw;-->
<!--            vertical-align: bottom;-->
<!--            }-->
<!--            .mall-text {-->
<!--            font-size: 12.8vw;-->
<!--            color: #646566;-->
<!--            line-height: 1;-->
<!--            }-->
<!--          </component>-->
<!--          <div class="mall">-->
<!--            <img class="mall-icon" src="@/assets/icons/ic_mall_nor.png" />-->
<!--            <div class="mall-text">启望商城</div>-->
<!--          </div>-->
<!--        </component>-->
<!--      </wx-open-launch-weapp>-->
      <van-tabbar-item replace to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="require(`@/assets/icons/ic_mine_${props.active ? 'sel' : 'nor'}.png`)" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
// import useWeixin from '@/composables/useWeixin';
import { computed } from 'vue';
import { xxpTrace } from '@/httpService/services'

// useWeixin([], ['wx-open-launch-weapp']);
const envVersion = computed(() => {
  if (process.env.VUE_APP_ENV === 'production') return 'release'
  return 'trial'
})

async function onLaunch() {
  xxpTrace()
}
</script>

<style lang="less">
.index {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__view {
    flex-grow: 1;
    overflow: auto;
  }

  .van-tabbar__placeholder {
    flex-shrink: 0;
  }
}
</style>
