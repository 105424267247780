<template>
  <router-view #="{ Component, route }">
    <keep-alive>
      <component :is="Component" v-if="route.meta.keepAlive" :key="route.name"></component>
    </keep-alive>
    <component :is="Component" v-if="!route.meta.keepAlive" :key="route.name"></component>
  </router-view>
</template>

<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';
//
// window.addEventListener('popstate', () => {
// 	console.log('关闭微信页面')
// 	window.WeixinJSBridge.invoke('closeWindow')
// })
// import { getRemainSum } from '@/httpService/services';
// import { useStore } from 'vuex';

// const store = useStore();

// const balanceNum = ref();

// async function getMyBalance() {
//   const { availablePoints } = await getRemainSum();
//   balanceNum.value = +(availablePoints / 100).toFixed(2);
//   store.commit('exam/setBalance', balanceNum.value);
// }
// getMyBalance();

const route = useRoute();
watch(route, () => {
  if (route.meta?.title) {
    document.title = route.meta.title;
  }
});
</script>

<style lang="less">
#app {
  // width: 100vw;
  // height: 100vh;
  // overflow: auto;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--van-gray-8);
}
.van-nav-bar.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  .van-icon {
    color: black;
  }
  &::after {
    display: none;
  }
}
</style>
